<template>
  <section>
    <v-carousel
      cycle
      hide-delimiter-background
      :show-arrows="false"
      delimiter-icon="mdi-minus"
      :height="sliderHeight"
    >
      <v-carousel-item
        class="top-banner-home"
        v-for="(slider, i) in sliders"
        :key="i"
        :src="slider.img"
      ></v-carousel-item>
    </v-carousel>
    <!--视频-->
    <v-container  grid-list-md text-center class="ctk-div">
      <v-layout
        text-xs-center
        wrap
        class="ctk-video-div"
      >
        <v-flex xs12 sm12 md8 lg8 class="ctk-video" style="padding:0;">
          <video height="100%" controls style="padding:0;background:#000;object-fit:fill;" id="player" poster="https://ctk-website.oss-cn-shanghai.aliyuncs.com/20190923/51977d2323b509392b2d9b5c6759cc53.png">
            <source src="https://citiking-web.oss-cn-beijing.aliyuncs.com/video/RENOLIT_MIN.mp4" type="video/mp4" />
          </video>
        </v-flex>
        <v-flex xs12 sm12 md8 lg4 style="padding: 20px; color: #fff; height: auto; min-height: 200px;display:table;" class="blue text-left ">
          <v-flex style="border: 3px solid #fff; height:100%; width:100%; display:table-cell; vertical-align:middle;" pl-5>
            <h6 style="font-size: 32px; margin-bottom: 30px;">Citiking Shanghai</h6>
            <span style="font-size: 16px;">专注于将欧洲制造商的高端建材、装饰和整体家居产品提供给中国用户。</span>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-container>
    <!--产品-->
    <v-container text-center class="ctk-div">
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 class="text-center">
          <h5 class="ctk-div-title">{{this.$t("homePageSection.products")}}</h5>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 text-center class="ctk-home-tab">
          <template>
            <v-tabs centered v-model="tabProducts">
              <v-tab
                v-for="(cat, k) in info.products.category"
                :key="k"
                :href="'#tab-products-' + cat.id"
              >
                {{cat.name}}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabProducts">
              <template v-for="cat in info.products.category">
                <v-tab-item :key="cat.id" :value="'tab-products-' + cat.id" >
                  <v-container
                    fluid
                    grid-list-sm
                  >
                    <v-layout wrap>
                      <v-flex
                        v-for="(product, i) in info.products.data[cat.id]"
                        :key="i"
                        xs12
                        mb-6
                        md4
                        :class="{smallProduct: i != 1 && innerWidth >960}"
                      >
                        <v-hover v-slot:default="{ hover }">
                        <v-card
                          :elevation="hover ? 12 : 2"
                          class="mx-auto"
                        >
                        <a :href="'/products/detail/' + product.id">
                          <v-img
                            :src="product.img"
                            aspect-ratio="1"
                          ></v-img>
                        </a>
                        </v-card>
                        </v-hover>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="text-center ctk-more-btn-div">
                    <v-btn class="ma-2 ctk-more-btn" outlined color="#333" :href="'products/'+cat.id">更多产品</v-btn>
                  </div>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
    <!--新闻-->
    <div style="background:#F8F8F8;">
    <v-container text-center class="ctk-div ctk-div-news">
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 class="text-center">
          <h5 class="ctk-div-title">{{this.$t("homePageSection.news")}}</h5>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 text-center class="ctk-home-tab">
          <template>
            <v-tabs centered v-model="tabNews">
              <v-tab
                v-for="(cat, k) in this.$t('newsCategory')"
                :key="k"
                :href="'#tab-news-' + cat.id"
              >
                {{cat.name}}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabNews">
              <template v-for="c in this.$t('newsCategory')">
                <v-tab-item :key="c.id" :value="'tab-news-' + c.id" >
                <v-container
                  fluid
                  grid-list-sm
                  style="background: #F8F8F8;"
                >
                  <v-layout wrap>
                    <v-flex
                      v-for="(item, n) in info.news[c.id]"
                      :key="n"
                      xs12
                      md4
                      :class="{'md12 lg12': n==0}"
                    >
                      <template v-if="n==0">
                        <v-layout wrap>
                        <v-flex xs12 sm4 md4 lg4>
                          <v-hover v-slot:default="{ hover }">
                            <v-card
                              :elevation="hover ? 12 : 2"
                              class="mx-auto"
                            >
                              <a :href="'/news/detail/' + item.id">
                                <v-img
                                  :src="item.img"
                                  to="http://www.baidu.com"
                                  aspect-ratio="1"
                                  max-height="330px"
                                ></v-img>
                              </a>
                            </v-card>
                          </v-hover>
                        </v-flex>
                        <v-flex xs12 sm8 md8 lg8 pa-10 class="text-left" style="position: relative;">
                          <a :href="'/news/detail/' + item.id">
                          <h3 class="ctk-news-title">{{item.title}}</h3>
                          <section class="ctk-news-profile">{{item.profile}}</section>
                          </a>
                          <div style="position:absolute; bottom: 0;">
                            <span>{{publish}}：</span><span>{{item.create_time.substring(0, 10)}}</span>
                          </div>
                        </v-flex>
                        </v-layout>
                      </template>
                      <template v-else>
                        <v-layout wrap justify-space-between style="position: relative;"  mt-10>
                        <div
                          class="text-center ctk-news-publish"
                          style="position: absolute; top: 10px; left: 40px; z-index: 99999; height: 90px; width:90px; color: white; padding-top: 10px;"
                          :id="'news_publish_' + item.id"
                        >
                          <h3 style="line-height: 40px; font-size: 24px;">{{item.create_time.substring(5, 10)}}</h3>
                          <h6 style="line-height: 30px; font-size: 14px;">{{item.create_time.substring(0, 4)}}</h6>
                        </div>
                        <v-flex mt-10>
                          <v-hover v-slot:default="{ hover }">
                          <a :href="'/news/detail/' + item.id">
                          <v-card
                            :elevation="hover ? 12 : 2"
                            min-height="240px"
                            max-width="360px"
                            class="mx-auto text-left"
                            style="padding-top: 60px;"
                            @mouseenter="newsPublishEnter(item.id)" @mouseleave="newsPublishLeave(item.id)"
                          >
                            <v-card-title pt-10 style="font-size: 16px; color: #333;word-wrap: break-spaces; display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                              {{item.title}}
                            </v-card-title>
                            <v-card-text style="font-size: 12px; color: #666;">
                              {{item.profile.substring(0, 90)}}...
                            </v-card-text>
                          </v-card>
                          </a>
                          </v-hover>
                        </v-flex>
                        </v-layout>
                      </template>
                    </v-flex>
                  </v-layout>
                </v-container>
                  <div class="text-center ctk-more-btn-div">
                    <v-btn class="ma-2 ctk-more-btn" outlined color="#333" :href="'/news/'+c.id">更多新闻</v-btn>
                  </div>
                </v-tab-item>
              </template>
            </v-tabs-items>
            </template>
        </v-flex>
      </v-layout>
    </v-container>
    </div>
    <!--案例-->
    <v-container text-center class="ctk-div">
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 class="text-center">
          <h5 class="ctk-div-title">{{this.$t("homePageSection.cases")}}</h5>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 sm12 md12 lg12 text-center class="ctk-home-tab">
          <template>
            <v-tabs centered v-model="tabCases">
              <v-tab
                v-for="(cat, k) in info.cases.category"
                :key="k"
                :href="'#tab-cases-' + cat.id"
              >
                {{cat.name}}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabCases">
              <template v-for="cat in info.cases.category">
                <v-tab-item :key="cat.id" :value="'tab-cases-' + cat.id" >
                  <v-container
                    fluid
                    grid-list-sm
                  >
                    <v-layout wrap>
                      <template v-for="(cse, i) in info.cases.data[cat.id]">
                        <v-flex
                          :key="i"
                          xs12
                          md4
                          mb-6
                          pa-md-4
                          :class="{'md6 lg6': i>2}"
                        >
                          <template>
                            <v-hover>
                              <template v-slot:default="{ hover }">
                                <v-card
                                  class="mx-auto"
                                >
                                  <v-img
                                    :src="cse.img"
                                    aspect-ratio="1"
                                    max-width="360px;"
                                    max-height="360px"
                                  ></v-img>
                                  <v-fade-transition>
                                    <v-overlay
                                      v-if="hover"
                                      absolute
                                      color="#036358"
                                      class="text-left"
                                      style="padding:20px;"
                                    >
                                      <p style="font-size: 24px; color: #FFF;">{{cse.title}}</p>
                                      <v-btn class="ma-2" outlined style="border:none;" :href="'/cases/detail/' + cse.id"> {{detail}}> </v-btn>
                                    </v-overlay>
                                  </v-fade-transition>
                                </v-card>
                              </template>
                            </v-hover>
                          </template>
                        </v-flex>
                      </template>
                    </v-layout>
                  </v-container>
                  <div class="text-center ctk-more-btn-div">
                    <v-btn class="ma-2 ctk-more-btn" outlined color="#333" :href="'/cases/'+cat.id">更多案例</v-btn>
                  </div>
                </v-tab-item>
              </template>
            </v-tabs-items>
          </template>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    sliders: null,
    pageId: 0,
    tabProducts: null,
    tabNews: null,
    tabCases: null,
    innerWidth: window.innerWidth,
    detail: null,
    publish: null,
    sliderHeight: 520
  }),
  created () {
    if (window.innerWidth < 600) {
      this.sliderHeight = 220
    } else if (window.innerWidth < 960) {
      this.sliderHeight = 360
    } else {
      this.sliderHeight = 520
    }
    document.querySelector('#menu_home').classList.add('v-btn--active')
    this.getPageData()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.$nextTick(function () {
          this.sliders = val.banner[this.pageId]
        })
        this.info.news = val.news
        this.info.products = {
          category: val.product.category[locale],
          data: val.product.data
        }
        this.info.cases = {
          category: val.cases.category[locale],
          data: val.cases.data
        }
      },
      deep: true
    }
  },
  computed: {
    info () {
      let sliders = { '0': [] }
      let productCategory = {}
      let products = { 'category': {}, 'data': [] }
      let caseCategory = {}
      let cases = { 'category': {}, 'data': [] }
      let news = { '2': [], '3': [] }
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        sliders = this.$store.getters.companyInfo.banner[this.pageId]
      }
      if (this.$store.getters.companyInfo.product) {
        productCategory = this.$store.getters.companyInfo.product.category[locale]
        products = this.$store.getters.companyInfo.product.data
      }
      if (this.$store.getters.companyInfo.cases) {
        caseCategory = this.$store.getters.companyInfo.cases.category[locale]
        cases = this.$store.getters.companyInfo.cases.data
      }
      if (this.$store.getters.companyInfo.news) {
        news = this.$store.getters.companyInfo.news
      }
      return {
        sliders: sliders,
        products: {
          category: productCategory,
          data: products
        },
        cases: {
          category: caseCategory,
          data: cases
        },
        news: news
      }
    }
  },
  methods: {
    getPageData () {
      this.detail = this.$t('detail')
      this.publish = this.$t('publish')
    },
    newsPublishEnter (id) {
      document.getElementById('news_publish_' + id).classList.add('ctk-news-publish-enter')
    },
    newsPublishLeave (id) {
      document.getElementById('news_publish_' + id).classList.remove('ctk-news-publish-enter')
    }
  }
}
</script>

<style>

</style>
